<script setup lang="ts">
import {DetailResponse} from '@eli5/js-helpers'
import {PageResource} from '@/resources/PageResource'
import { notifySentry } from '@/helpers/notifySentry'

const route = useRoute()
const {locale} = useI18n()
const runtimeConfig = useRuntimeConfig()

const Resource = DetailResponse.extend({
        data: PageResource,
      })
const {data} = await useFetch(`/pages/${locale.value}/${route.params.slug}`, {
  method: 'GET',
  baseURL: runtimeConfig.public.apiBase,
  transform: data => {
    try {
      return Resource.parse(data).data
    } catch (e) { notifySentry(e) }
  }
})

useSeoMeta({
  title: () => `${data.value?.title ?? ''}`,
  description: () => `${data.value?.description ?? ''}`,
  ogImage: () => data.value?.sections?.[0]?.images?.[0]?.url ?? ''
})

defineOgImage({
  title: `${data.value?.title ?? ''}`,
  description: `${data.value?.description ?? ''}`,
  backgroundColor: 'bg-green-500',
})
</script>

<template>
  <div class="page-wrapper" v-if="data">
    <template v-for="(section, index) in data.sections" :key="section.id">
      <header v-if="index === 0">
        <div class="container" v-if="section.images && section.images.length > 0">
          <figure v-for="image in section.images">
            <nuxt-img :src="image.url" :alt="image.alt" width="1400" />
          </figure>
        </div>
        <div class="heading container">
          <h1 v-if="section.title">{{ section.title }}</h1>
          <div v-if="section.content" v-html="section.content"></div>
        </div>
      </header>
      <section v-else class="container" >
        <h2 v-if="section.title">{{ section.title }}</h2>
        <div v-if="section.content" v-html="section.content"></div>
      </section>
    </template>
  </div>
  <ErrorPage v-else></ErrorPage>
</template>

<style lang="postcss" scoped>
.page-wrapper {
  padding-block: var(--container-block-padding);
  & header {
    & figure {
      overflow: hidden;
      width: 100%;
      object-fit: cover;
      margin: 0;
      padding: 0;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }
  & .heading,
  & section {
    color: var(--text-color-secondary);
    font-size: 2rem;
    line-height: 2.8rem;
    --container-width: 72ch;
    & h1 {
      margin-block-end: 5rem;
    }
    & h2, h3, h4, h5 {
      margin-block-end: 1.6rem;
    }
  }
}
</style>
